import styled from 'styled-components';
import { H1, H2, Btn, Paragraph, NavigationArrow, CenterVertical } from './generalStyles';

interface uvPageProps {
    bgColor?: string;
    uvIndex?: number;
    isFromTop?: boolean;
}
export const UvCenterContainer = styled(CenterVertical)`
    flex-direction: column;
    justify-content: center;
    & img {
        width: 25%;
        max-height: 75px;
        object-fit: contain;
    }
`;
export const UvContainer = styled.div<uvPageProps>`
    background-color: ${(uvPageProps) => uvPageProps.bgColor};
    box-sizing: border-box;
    position: relative;
    height: 100vh;
    width: 100%;
    padding: 5% 8%;
    overflow: hidden;
    color: #000;
    scroll-snap-align: center;
`;
export const UvNumber = styled.img<uvPageProps>`
    position: absolute;
    z-index: 0;
    ${(uvPageProps) =>
        uvPageProps.uvIndex !== undefined
            ? uvPageProps.uvIndex > 9
                ? 'width: 94%; left: 3%'
                : 'width: 80%; left: 10%'
            : ''};
    max-height: 50%;
    top: 25%;
    text-align: center;
    @media (min-width: 750px) {
        top: 5%;
        left: 5%;
    }
`;
export const UvTextContainer = styled.div`
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
`;
export const UvIconContainer = styled.div`
    width: 100%;
    margin-top: 2rem;
    position: relative;
    z-index: 1;
    display: flex;
    justify-content: space-around;
    align-items: center;
    max-height: 100px;
`;
export const UvI = styled(H2)`
    margin: 0;
    font-size: 2rem;
    font-weight: normal;
`;
export const UvRisk = styled(H1)`
    margin: 0;
    margin-bottom: 1rem;
    font-size: 3rem;
    font-weight: normal;
`;
export const UvSubt = styled(H2)`
    margin: 0;
    width: 70%;
    font-size: 2rem;
    font-weight: normal;
`;
export const UvInfo = styled(Paragraph)`
    margin: 0;
    font-size: 1.2rem;
`;
export const UvNavArrow = styled(NavigationArrow)<uvPageProps>`
    ${(uvPageProps) => (uvPageProps.isFromTop ? 'top' : 'bottom')}: 5%;
`;

export const UvLocation = styled.div``;

export const UvLocationContainer = styled.div`
    position: relative;
    bottom: 120px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 1.2rem;
    div {
        max-width: 50%;
    }
    a {
        font-size: 1.2rem;
        color: #000;
    }
`;

export const UvBtn = styled(Btn)`
    font-size: 1.2rem;
    background-color: transparent;
    border-color: #000;
    color: #000;
`;