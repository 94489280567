import { federalStates } from "../assets/ts/locations";
import { TitleContainer } from "../assets/styles/generalStyles"
import {
    LocationH1,
    LocationP,
    LocationContainer,
} from '../assets/styles/locationsStyles';
import { Location } from '../assets/ts/customTypes';
import { useLocation } from "../lib/context/LocationContext";
import { determineRisk } from "./UVpage";
import { TheSun } from "../lib/components/TheSun";
import { ArrowToTop } from "../lib/components/ArrowToTop";
import { CollapsableLocationList } from "../lib/components/CollapsableLocationList";

export function PageLocations({
    uvIndex = 0,
    setUvIndex,
}: {
    uvIndex?: number;
    setUvIndex: (uvIndex: number) => void;
}) {
    const [currentLocation, setCurrentLocation] = useLocation();
    const uvColor = uvIndex !== 0 ? determineRisk(Math.round(uvIndex)).color : 'white';
    return (
        <>
            <TheSun isInText={false} bottom='25%' />
            <LocationContainer>
                <TitleContainer>
                    <LocationH1>
                        REGIONEN{'\n'}
                        UND ORTE
                    </LocationH1>
                </TitleContainer>
                <LocationP>In diesen Städten und Gemeinden sind wir bereits aktiv.</LocationP>
                <LocationP>Für welchen Ort möchten Sie den UV-Index abrufen?</LocationP>
                {
                    federalStates.map((federalState) => {
                        return (
                            <CollapsableLocationList
                                federalState={federalState}
                                currentLocation={currentLocation}
                                uvColor={uvColor}
                                handleSelection={handleSelection}
                                key={federalState.name}
                            />
                        );
                    })
                }
            </LocationContainer>
            <ArrowToTop />
        </>
    );

    function handleSelection(event: React.MouseEvent<HTMLLIElement>) {
        let allLocations: Location[] = [];
        federalStates.forEach((federalState) => {
            allLocations = allLocations.concat(federalState.locations);
        });
        const newLocation = allLocations.find((innerLocation) => innerLocation.name === event.currentTarget.id);
        if (!newLocation) {
            console.error(`Invalid location ${event.currentTarget.id}`);
            return;
        }
        updateUvIndex(newLocation).then(() => { setCurrentLocation(newLocation); });
    }

    function updateUvIndex(newLocation: Location) {
        return (async () => {
            const indexRes = await fetch('/get-uv-index.php', {
                method: 'POST',
                credentials: 'same-origin',
                body: JSON.stringify({ lat: newLocation.lat, long: newLocation.long, name: newLocation.name }),
            });
            if (indexRes.ok) {
                const indexJson: { ok: boolean; index: number; name: string } = await indexRes.json();
                if (indexJson.ok) {
                    setUvIndex(indexJson.index);
                }
            }
        })();
    }
}