import styled from "styled-components";
export const Webfont2 = 'Webfont2';
export const MaxWidth = '750px';

export interface StyleProps {
    isFixed?: boolean;
}

/*General*/
export const H1 = styled.h1`
    font-weight: 900;
    font-size: 3.7rem;
    position: relative;
    z-index: 1;
    line-height: 0.95;
    margin: 1rem 0;
    white-space: pre-wrap;
`;
export const H2 = styled.h2`
    white-space: pre-wrap;
`;
export const H4 = styled.h4`
    font-weight: 100;
`;
export const Paragraph = styled.p`
    position: relative;
    line-height: 1.25;
    white-space: pre-wrap;
    z-index: 2;
`;
export const Btn = styled.button`
    position: relative;
    z-index: 2;
    background-color: #000;
    color: #fff;
    padding: 0.5rem 0.75rem;
    border: 1px solid #fff;
    cursor: pointer;
`;
export const Link = styled.a`
    margin: 2rem 0;
    font-weight: 100;
    font-size: 1rem;
    cursor: pointer;
    z-index: 2;
`;
export const NavigationArrow = styled.img<StyleProps>`
    ${(styleProps) => (styleProps.isFixed ? 'position: fixed; left: 45%; z-index: 11;' : 'position: relative;')};
    width: 3rem;
    height: auto;
    cursor: pointer;
`;
export const CenterContainer = styled.div`
    width: 100%;
    max-width: ${MaxWidth};
    text-align: center;
`;
export const NavArrowContainer = styled.div`
    margin: 2rem;
    text-align: center;
`;
export const ContentContainer = styled.div`
    padding-left: 9%;
    padding-top: 5rem;
    width: 82%;
`;
export const CenterVertical = styled.div`
    display: flex;
    align-items: center;
    height: 100%;
`;
export const TitleContainer = styled.div`
    margin-top: 16px;
    & H1 {
        margin: 0;
    }
`;

export const LinkAsButton = styled.a`
    position: relative;
    z-index: 2;
    background-color: #000;
    color: #fff;
    padding: 0.5rem 0.75rem;
    border: 1px solid #fff;
    cursor: pointer;
    white-space: break-spaces;
    text-decoration: none;
`;
export const Ul = styled.ul`
    list-style-type: none;
    padding-inline-start: 20px;
    position: relative;

    && li:before {
        content: '- ';
    }
    && li {
        padding-top: 5px;
    }
`;