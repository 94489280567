import { useEffect, useRef, useState } from "react";
import { UVpage } from './UVpage';
import arrowU from '../assets/icons/arrow-up.png';
import arrowD from '../assets/icons/arrow-down.png';
import { IndexContainer } from '../assets/styles/uvIndexStyles';
import { UvNavArrow } from "../assets/styles/uvPageStyles";
import { PageNames } from "../assets/ts/customTypes";

export function PageUVindex({
    isNavigationInstant,
    uvIndex,
    setNextPage,
}: {
    isNavigationInstant: boolean;
    uvIndex: number;
    setNextPage: (nextPage: PageNames) => void;
}) {
    const currentUV = Math.round(uvIndex);
    const uvPages = buildUvPages();
    const [visibleUV, setVisibleUV] = useState(currentUV);
    const [isNavInstant, setIsNavInstant] = useState(isNavigationInstant);
    const [isNavArrow, setIsNavArrow] = useState(false);
    const scrollRef = useRef<HTMLDivElement>(null);
    const vHeight = window.innerHeight;

    useEffect(() => {
        if (isNavInstant) {
            setIsNavInstant(false);
            document.getElementById('UV-' + visibleUV)?.scrollIntoView({ block: 'start' });
        } else if (isNavArrow) {
            setIsNavArrow(false);
            document.getElementById('UV-' + visibleUV)?.scrollIntoView({ block: 'start', behavior: 'smooth' });
        }
    }, [visibleUV]);

    return (
        <>
            {visibleUV < 11 && (
                <UvNavArrow isFromTop={true} isFixed={true} src={arrowU} alt='' onClick={() => uvNavigation(true)} />
            )}
            {visibleUV > 1 && (
                <UvNavArrow isFromTop={false} isFixed={true} src={arrowD} alt='' onClick={() => uvNavigation(false)} />
            )}
            <IndexContainer ref={scrollRef} onScroll={handleScroll}>
                {uvPages}
            </IndexContainer>
        </>
    );

    function handleScroll() {
        if (scrollRef.current !== null) {
            let oldOffset = document.getElementById('UV-' + visibleUV)!.offsetTop;
            let currentOffset = scrollRef.current.scrollTop;
            if (currentOffset !== oldOffset) {
                setVisibleUV(visibleUV + calcOffsetHeight(oldOffset, currentOffset, oldOffset > currentOffset));
            }
        }
    }

    function calcOffsetHeight(oldOffset: number, currentOffset: number, isOldBigger: boolean): number {
        if (isOldBigger) {
            return Math.round((oldOffset - currentOffset) / vHeight);
        } else {
            return Math.round(-1 * ((currentOffset - oldOffset) / vHeight));
        }
    }

    function uvNavigation(directionUp: boolean) {
        setIsNavArrow(true);
        if (visibleUV > 0 && visibleUV < 12) {
            setVisibleUV(directionUp ? visibleUV + 1 : visibleUV - 1);
        } else {
            setVisibleUV(currentUV);
        }
    }

    function buildUvPages() {
        let pages = [];
        for (let i = 11; i > 0; i--) {
            pages.push(<UVpage setNextPage={setNextPage} uvIndex={i} key={i} />);
        }
        return pages;
    }
};
