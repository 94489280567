export type UVinfo = {
    color: string;
    numColor: string;
    risk: string;
    subtitle: string;
    information: string;
};

export type Page = {
    name: PageNames;
    page: JSX.Element;
}

export enum PageNames {
    LANDING = 'Landingpage',
    INDEX = 'PageUVindex',
    TAN = 'PageHealthyTan',
    CAMPAIGN = 'PageCampaign',
    TIPS = 'PageTips',
    IMPRINT = 'PageImprint',
    LOCATIONS = 'PageLocations',
    DETAILS = 'PageDetails',
    SPONSORS = 'PageSponsors',
}

export type AnyJsx = any;

export type FederalState = {
    name: 'Schleswig-Holstein' | 'Bayern';
    locations: Location[];
};

export type Location = {
    name: LocationNames;
    lat: number;
    long: number;
};

export enum LocationNames {
    LIVE = 'Ihr Standort',
    DAHME = 'Dahme',
    ECKERNFOERDE = 'Eckernförde',
    FEHMARN = 'Fehmarn',
    GLUECKSBURG = 'Glücksburg',
    GROEMITZ = 'Grömitz',
    GRUBE = 'Grube',
    HOERNUM = 'Hörnum',
    KELLENHUSEN = 'Kellenhusen',
    LENSAHN = 'Lensahn',
    LIST = 'List',
    NEUSTADT = 'Neustadt',
    SCHWEDENECK = 'Schwedeneck',
    STRANDE = 'Strande',
    SYLT = 'Sylt',
    TIMMENDORF = 'Timmendorf',
    WENNINGSTEDT_BRADERUP = 'Wenningstedt-Braderup',
    CHIEMING = 'Chieming am Chiemsee',
    MUEHLDORF = 'Mühldorf am Inn',
    UEBERSEE = 'Übersee',
    BADENDORF = 'Bad Endorf',
    TRAUNREUT = 'Traunreut',
    BURGHAUSEN = 'Burghausen',
    KRAIBURG = 'Kraiburg am Inn',
    SEEONSEEBRUCK = 'Seeon-Seebruck',
    TRAUNSTEIN = 'Traunstein',
    AMPFING = 'Ampfing',
    NEUMARKTSTVEIT = 'Neumarkt-St. Veit',
    MARKTL = 'Marktl',
    PERACH = 'Perach',
    CUXHAVEN_GRIMMERSHORNBUCHT = 'Cuxhaven Grimmershörnbucht',
    CUXHAVEN_ALTENBRUCH = 'Cuxhaven Altenbruch',
    LUEBECK = 'Lübeck',
    ALTOETTING = 'Altötting',
    ROSENHEIM = 'Rosenheim',
    HELDENSTEIN = 'Heldenstein',
    SUEDERLUEGUM = 'Süderlügum',
    INGOLSTADT = 'Ingolstadt',
    LANDSBERG_AM_LECH = 'Landsberg am Lech',
    STRANDBAD_BERNAU_FELDEN = 'Strandbad am Chiemsee Bernau-Felden',
    GRUNDSCHULE_KIEFERSFELDEN = 'Grundschule Kiefersfelden',
    TOURIST_INFO_KIEFERSFELDEN = 'Tourist Info am Rathausplatz in Kiefersfelden',
    FREIBAD_NUSSDORF = 'Freibad Nußdorf am Inn',
    GRUNDSCHULE_NUSSDORF = 'Grundschule Nußdorf am Inn',
    BADEPLATZ_TINNINGER_SEE = 'Badeplatz Tinninger See',
    BADESEE_THANSAU = 'Badesee Thansau',
    SPORTPLATZ_HOELZL = 'Sportplatz Turner Hölzl',
    GESUNDHEITSAMT_ROSENHEIM = 'Gesundheitsamt Rosenheim',
    STAATLICHE_REALSCHULE_BRUCKMUEHL = 'Staatliche Realschule Bruckmühl',
    STAATLICHE_REALSCHULE_WASSERBURG = 'Staatliche Realschule Wasserburg',
    WIRTSCHAFTSSCHULE_BAD_AIBLING = 'Wirtschaftsschule Alpenland Bad Aibling',
};

export type Sponsor = {
    name: string;
    logo: string;
    link: string;
};